import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, filter, first, map } from 'rxjs';
import { AuthService, NgrxService } from 'src/app/services';

@Injectable()
export class PagesService {
	serviceName = 'permission';

	private permission = new BehaviorSubject<any>(null);
	public permission$ = this.permission.asObservable();

	menus = {
		jobfair: false,
		courses: false,
		company: false,
		profile: false,
		serverless: false,
		company_b2b: false,
		hubble: false,
		hubble_community: false,
		hubble_events: false,
		profile_open_door: false,
		profile_disabled_user: false,
		profile_mentor: false,
	};

	constructor(
		private db: AngularFirestore,
		public authService: AuthService,
		private router: Router,
		private ngrxService: NgrxService,
	) {
		this.ngrxService.init(this.serviceName, 'profile');

		firebase.auth().onAuthStateChanged((user: any) => {
			if (user) {
				this.db
					.doc('admin-tool/' + user.uid)
					.valueChanges()
					.pipe(first())
					.subscribe((data: any) => {
						if (!data || Object.keys(data).length === 0) {
							this.logout();
						} else {
							this.ngrxService.loadInitData(this.serviceName, 'profile', data);
						}
					});
			}
		});
	}

	logout() {
		this.authService
			.signOut()
			.then(data => {
				// comment
			})
			.catch(() => {
				// comment
			});
	}

	menuUpdate(str: any) {
		this.router.navigate([`app/${str}`]);
	}

	public getPermission() {
		return this.ngrxService.loadData(this.serviceName, 'profile').pipe(
			filter(({ isSkeleton }: any) => isSkeleton === false),
			map(({ data }: any) => data),
			first(),
		);
	}

	public getPermissionByName(name: string) {
		return this.getPermission().pipe(map((data: any) => data[name]));
	}

	public notValidPermissionByName(name: string, url: string) {
		return this.getPermission()
			.pipe(map((data: any) => data[name]))
			.subscribe((data: any) => {
				if (data) {
				} else {
					this.router.navigate([url]);
				}
			});
	}
}
